<template>
  <MiniShopMain />
</template>

<script>
import { mapState } from "vuex";
import MiniShopMain from "@/components/shop/MiniShopMain";
export default {
  components: {
    MiniShopMain,
  },
  computed: {
    ...mapState("shop", ["providerInfo"]),
  },
  metaInfo() {
    return {
      title: `${this.providerInfo.provider_name} - ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${this.providerInfo.provider_name} - ${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `램가게, ${this.providerInfo.provider_name} ,${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${this.providerInfo.provider_name} - ${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME} 램 가게 ${this.providerInfo.provider_name} `,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${process.env.VUE_APP_API_URL}/${this.providerInfo.main_visual}`,
        },
      ],
    };
  },
};
</script>

<style></style>
