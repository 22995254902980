<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      램 가게
      <div class="search">
        <div class="input_box_n">
          <input
            type="text"
            name=""
            v-model="keyword"
            @keydown.enter="searchData"
            placeholder="램 가게에서 검색하세요."
          />
        </div>
        <router-link to="" @click.native="searchData"
          ><img src="@/assets/images/sub/alarm_search_n_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_ms_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <div class="rn_ms_box_6">
        <div class="top">
          <img
            :src="
              providerInfo.banner_visual
                ? providerInfo.banner_visual
                : '/data/skin/interior_comfortable_gls/images/common/mns_banner.gif'
            "
            alt=""
          />
        </div>
        <div class="banner">
          <img
            :src="
              providerInfo.main_visual
                ? providerInfo.main_visual
                : '/data/skin/interior_comfortable_gls/images/common/mns_banner.gif'
            "
            alt=""
          />
        </div>
        <ul class="cate">
          <li class="on"><a href="#">전체상품</a></li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
        <div class="goods">
          <div class="goods_like">
            <span class="shop_name">
              {{ providerInfo.provider_name }}
            </span>
            <span>
              <img
                @click="setWish('N')"
                src="@/assets/images/like_on.png"
                alt=""
                v-if="providerInfo.wish_yn === 'Y'"
              />
              <img
                @click="setWish('Y')"
                src="@/assets/images/like_off.png"
                alt=""
                v-else
              />
              이 가게 찜하기
            </span>
          </div>
          <div class="goods_list" v-if="listArray.length > 0">
            <div
              class="box"
              v-for="(list, index) in listArray"
              :key="list.goods_seq"
            >
              <router-link to="" @click.native="movePage(index)">
                <div class="img">
                  <img
                    :src="list.image"
                    @error="replaceImg"
                    :alt="list.goods_name"
                  />
                </div>
                <div class="txt">
                  <div class="left">{{ list.goods_name }}</div>
                </div>
              </router-link>
            </div>
          </div>
          <div class="goods_list none" v-else>
            <p>검색된 상품이 없습니다.</p>
          </div>
        </div>
        <div class="rn_more">
          <router-link
            to=""
            @click.native="getMiniShop()"
            v-if="parseInt(totalPage) > parseInt(page)"
            >{{ limit }}개 더보기<img
              src="@/assets/images/sub/my_down_ico.png"
              alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
export default {
  data() {
    return {
      providerSeq: this.$route.params.providerSeq || 0,
      page: 0,
      keyword: "",
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });

    this.getMainMenu();
  },
  components: {
    MainMenu,
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("mypage", ["myInfo"]),
    ...mapState("shop", [
      "providerInfo",
      "listArray",
      "result",
      "msg",
      "limit",
      "totalPage",
    ]),
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
    searchData() {
      // if (this.keyword.replace(/\s/gi, "").length <= 0) {
      //   this.$toast.default("검색어를 입력해주세요.");
      //   return false;
      // }
      this.getMiniShop(true);
    },
    movePage(index) {
      const data = this.listArray[index];
      const url = `/market_goods_detail/${data.id}/${data.goods_seq}`;
      this.$router.push(url);
    },
    async setWish(yn) {
      if (!this.needsLogin()) return false;

      //reviewer_seq 파라메터는 상황에 따라 리뷰어, 리뷰, 가게 번호가 됨
      await this.$store.dispatch("wish/updateWishShop", {
        reviewer_seq: this.providerInfo.provider_seq,
        wish_yn: yn,
      });
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("shop/updateWishShop", yn);
      }
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getMiniShop();
    },
    async getMiniShop(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("shop/getMiniShop", {
        provider_seq: this.providerSeq,
        page: this.page,
        keyword: this.keyword,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        this.$router.push("/");
      }
      this.page++;
    },
    getMenu(num) {
      if (parseInt(num) == 1) {
        this.$router.push("/market_main_list").catch(() => {});
        return false;
      }
      if (parseInt(num) == 3) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop_name {
  float: left;
  font-weight: 600;
}
.goods_list {
  &.none {
    text-align: center;
    p {
      margin-top: 30px;
    }
  }
}
</style>
